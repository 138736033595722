import React, { PropsWithChildren, createContext, useState } from 'react';

export interface IRatingContext {
  ratingOpen: boolean;
  setRatingOpen: (open: boolean) => void;
  isLoading: boolean;
  send: (data: any, onSuccess: () => void, onError: () => void) => void;
}

export const RatingContext = createContext<IRatingContext>(null!);

export default function RatingProvider({ children }: PropsWithChildren) {
  const [isLoading, setIsLoading] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);

  const send = async (
    { reference, ...rest }: any,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
  ) => {
    setIsLoading(true);
    const endpoint = process.env.REACT_APP_ENDPOINT + '/meet/' + reference + '/reviews/add ';
    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ ...rest }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => onSuccess(res.message))
      .catch(res => onError(res.data?.error || res.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <RatingContext.Provider value={{ ratingOpen, setRatingOpen, isLoading, send }}>{children}</RatingContext.Provider>
  );
}
