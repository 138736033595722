import React, { useMemo } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';
import useRatingContext from '../RatingProvider/useRating';
import { styled } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

const CountdownContainer = styled('div')({
  position: 'absolute',
  zIndex: 5,
  // left: '10%',
  // top: '8%',
  fontSize: 32,
  fontWeight: 600,
  backgroundColor: 'rgba(255, 255, 255,0.3)',
  paddingLeft: 12,
  paddingRight: 12,
  borderEndEndRadius: 10,
});

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantProps) {
  const { room, startAt, endAt } = useVideoContext();

  const { setRatingOpen } = useRatingContext();

  const date = useMemo(() => {
    if (startAt && endAt) {
      // const formatedStartAt = moment(startAt);
      const formatedEndAt = moment.tz(endAt, 'Africa/Casablanca');
      // const dateNow = moment();
      return formatedEndAt;
    }
    return null;
  }, [startAt, endAt]);

  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      isDominantSpeaker={isDominantSpeaker}
    >
      {!!date && !!room && (
        <CountdownContainer>
          <Countdown
            date={date.toDate()}
            onComplete={() => {
              room.disconnect();
              setRatingOpen(true);
            }}
            renderer={({ hours, minutes, seconds }) => (
              <span>
                {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
              </span>
            )}
          />
        </CountdownContainer>
      )}
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default React.memo(Participant);

const formatTime = (time: number) => {
  return time > 9 ? time : '0' + time;
};
