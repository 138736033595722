import React, { useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import RatingProvider from './components/RatingProvider';
import RatingDialog from './components/RatingDialog/RatingDialog';
import useRatingContext from './components/RatingProvider/useRating';

import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useParams } from 'react-router-dom';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

type Props = {
  room: {
    id: string;
    name: string;
  };
  userIdentity: string;
  userName: string;
  userType: 'customer' | 'expert';
  userToken: string;
};

export default function AppContainer(props: Props) {
  return (
    <RatingProvider>
      <App {...props} />
    </RatingProvider>
  );
}

function App(props: Props) {
  const { reference } = useParams<{ reference: string }>();
  const { userType, userToken } = props;
  const roomState = useRoomState();
  const { ratingOpen, setRatingOpen } = useRatingContext();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const initSessionEcho = () => {
    const sessionEcho = new Echo({
      broadcaster: 'pusher',
      Pusher,

      // Tweak the options according to your settings
      key: 'fc21d3a8b5d4732ec677', // set the key defined in your .env
      // the host defined in your .env/ the host defined in your .env
      wsHost: 'ws.khabiry.com',
      wssHost: 'ws.khabiry.com',
      wsPort: 443,
      wssPort: 443, // or the port defined in your .env // or the port defined in your .env
      cluster: 'us3',
      forceTLS: false,
      disableStats: false,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: { Authorization: `Bearer ${userToken}` },
      },
      authEndpoint: 'https://app.khabiry.com/broadcasting/auth',
    });

    sessionEcho.join(`online.${reference}`);
  };

  useEffect(() => {
    initSessionEcho();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ height }}>
      {userType === 'customer' && <RatingDialog open={ratingOpen} onClose={() => setRatingOpen(false)} />}
      {roomState === 'disconnected' ? (
        <PreJoinScreens {...props} />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
