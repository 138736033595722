import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, styled } from '@material-ui/core';
import StarIcon from '../../icons/StarIcon';
import { Controller, useForm } from 'react-hook-form';
import useRatingContext from '../RatingProvider/useRating';
import { useParams } from 'react-router-dom';

const Description = styled('p')({
  fontSize: 16,
  margin: 8,
});

const Comment = styled('textarea')({
  fontSize: 14.4,
  borderRadius: 4,
  borderColor: 'rgba(0, 0, 0, 0.4)',
  borderWidth: 1,
  marginTop: 16,
  padding: 10,
  width: '100%',
  resize: 'none',
  fontWeight: 'normal',
  fontFamily: 'Inter',
  '&::placeholder': {
    color: '#999999',
  },
});

const StarsContainer = styled('ul')({
  display: 'flex',
  justifyContent: 'center',
  gap: 8,
  listStyleType: 'none',
  padding: 0,
});

const Error = styled('p')({
  fontSize: 12,
  color: 'red',
  marginTop: 4,
  textAlign: 'start',
});

type FormValues = {
  title: string;
  rating: number;
  body: string;
};

interface RatingDialogProps {
  open: boolean;
  onClose(): void;
}

export default function RatingDialog({ open, onClose }: PropsWithChildren<RatingDialogProps>) {
  const { reference } = useParams<{ reference: string }>();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  const { send, isLoading } = useRatingContext();

  const onSubmit = (values: FormValues) => {
    send(
      {
        reference,
        ...values,
      },
      () => {
        onClose();
      },
      () => {}
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle style={{ paddingBottom: 0, textAlign: 'center' }}>
        Rating
        <Description>Rate your meeting</Description>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Controller
          control={control}
          name="rating"
          render={({ field: { value, onChange } }) => (
            <div>
              <StarsPicker value={value} onChange={onChange} />
            </div>
          )}
        />

        <div>
          <TextField
            placeholder="Tittre"
            variant="outlined"
            fullWidth
            size="small"
            id="title"
            {...register('title', { required: { value: true, message: 'Ce champs est obligatoire' } })}
          />
          {errors.title && <Error>{errors.title?.message}</Error>}
        </div>

        <Comment id="comment" placeholder="Commentaire" rows={6} {...register('body')} />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)} color="primary" variant="contained" autoFocus>
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type StarsPickerProps = {
  value: number;
  onChange: (value: number) => void;
};

function StarsPicker({ value, onChange }: StarsPickerProps) {
  return (
    <StarsContainer>
      {Array.from({ length: 5 }).map((_, index) => (
        <li key={index} style={{ cursor: 'pointer' }} onClick={() => onChange(index + 1)}>
          <StarIcon isActive={value >= index + 1} />
        </li>
      ))}
    </StarsContainer>
  );
}
