import React from 'react';

type Props = {
  isActive: boolean;
};

export default function StarIcon({ isActive }: Props) {
  return (
    <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.53834 0.609966C6.70914 0.199319 7.29086 0.199318 7.46166 0.609964L8.99874 4.30556C9.07075 4.47868 9.23355 4.59696 9.42045 4.61194L13.4102 4.9318C13.8535 4.96734 14.0332 5.52059 13.6955 5.80993L10.6557 8.41378C10.5133 8.53576 10.4512 8.72715 10.4947 8.90952L11.4234 12.8028C11.5265 13.2354 11.0559 13.5773 10.6764 13.3455L7.26063 11.2592C7.10062 11.1615 6.89938 11.1615 6.73937 11.2592L3.32363 13.3455C2.94408 13.5773 2.47345 13.2354 2.57665 12.8028L3.50534 8.90952C3.54884 8.72715 3.48665 8.53576 3.34426 8.41378L0.304527 5.80993C-0.0332418 5.52059 0.14652 4.96734 0.589847 4.9318L4.57955 4.61194C4.76645 4.59696 4.92925 4.47868 5.00126 4.30556L6.53834 0.609966Z"
        fill={isActive ? '#FDB022' : '#A9A9A9'}
      />
    </svg>
  );
}
