import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch, useParams, useLocation } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError, signIn, isFirstFetching } = useAppState();
  const { uuid, reference } = useParams<{ uuid: string; reference: string }>();
  const { search } = useLocation();
  const [room, setRoom] = useState<{ id: string; name: string }>();
  const [userIdentity, setUserIdentity] = useState('');
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState<'customer' | 'expert'>('customer');
  const [userToken, setUserToken] = useState('');

  const connectionOptions = useConnectionOptions();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const hash = params.get('token');
    if (uuid && reference && hash && isFirstFetching === null) {
      signIn(uuid, reference, hash).then(resp => {
        if (resp.success) {
          setUserIdentity(resp.user_identity);
          setUserName(resp.user_name);
          setUserType(resp.user_type);
          setUserToken(resp.token);
          setRoom(resp.room);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, reference, search]);

  if (isFirstFetching && isFirstFetching === null) return <h1>...Loading</h1>;

  if (!room) return null;

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App room={room} userIdentity={userIdentity} userName={userName} userType={userType} userToken={userToken} />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            {/* <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute> */}
            <PrivateRoute path="/:uuid/:reference">
              <VideoApp />
            </PrivateRoute>
            {/* <Route path="/login">
              <LoginPage />
            </Route>
            <Redirect to="/" /> */}
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
