import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

type Props = {
  room: {
    id: string;
    name: string;
  };
  userIdentity: string;
  userName: string;
};

export default function PreJoinScreens({ room, userIdentity, userName }: Props) {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (room) {
      // if (user?.displayName) {
      setStep(Steps.deviceSelectionStep);
      // }
    }
  }, [user, room]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
  //   // @ts-ignore
  //   if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
  //     window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
  //   }
  //   setStep(Steps.deviceSelectionStep);
  // };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {/* {step === Steps.roomNameStep && <RoomNameScreen name={name} setName={setName} handleSubmit={handleSubmit} />} */}

      {/* {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )} */}
      <DeviceSelectionScreen userName={userName} userIdentity={userIdentity} room={room} setStep={setStep} />
    </IntroContainer>
  );
}
